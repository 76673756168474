<template>
  <b-modal
    ref="modal-disburement-document"
    hide-footer
    :title="titleExcel"
    id="modal-disburement-document"
  >
    <b-row class="mb-4">
      <b-col cols="4">
        <b-form-select
          size="sm"
          class="select-style"
          v-model="apiParams.entityRefType"
        >
          <b-form-select-option value="0">Tất cả</b-form-select-option>
          <b-form-select-option value="1">Hoá đơn</b-form-select-option>
          <b-form-select-option value="2">Đơn hàng</b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="4">
        <date-picker
          placeholder="Từ"
          class="form-control form-control-sm"
          :config="dpConfigs.date"
          v-model="apiParams.fromDate"
        ></date-picker>
      </b-col>
      <b-col cols="4">
        <date-picker
          placeholder="Đến"
          class="form-control form-control-sm"
          :config="dpConfigs.date"
          v-model="apiParams.toDate"
        ></date-picker>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="4">
        <b-form-select
          size="sm"
          class="select-style"
          v-model="apiParams.compareWith"
        >
          <b-form-select-option value="0">So khớp tất cả</b-form-select-option>
          <b-form-select-option value="1"
            >So khớp với giải ngân</b-form-select-option
          >
        </b-form-select>
      </b-col>
      <b-col cols="8"> </b-col>
    </b-row>
    <b-overlay
      :show="isMatchDocument"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
      @hidden="$refs.button.focus()"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner variant="light" small type="grow"></b-spinner>
        </div>
      </template>
      <b-button variant="primary" size="sm" @click="onMatchingDataDocument">
        <i style="font-size: 1rem" class="fas fa-search mr-1"></i>
        <strong>Tìm kiếm</strong>
      </b-button>
    </b-overlay>

    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide('modal-disburement-document')"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<script>
// import datePicker from 'vue-bootstrap-datetimepicker';
import timeUtils from '@/utils/date';

export default {
  props: ['isMatchDocument'],
  mixins: [],
  data() {
    return {
      dpConfigs: null,
      entityRefType: '0',
      titleExcel: 'Tìm kiếm chứng từ',
      apiParams: {
        fromDate: null,
        toDate: null,
        compareWith: '0',
        entityRefType: '0',
      },
    };
  },
  components: {
    // datePicker,
  },
  computed: {},
  created() {
    this.dpConfigs = timeUtils.DP_CONFIG;
  },
  watch: {},
  validations: {},
  methods: {
    onMatchingDataDocument() {
      this.$emit('match-data-document', this.apiParams);
    },
  },
};
</script>

<style lang="scss">
#modal-disburement-document {
  .date-style {
    display: flex;
    align-items: center;
  }
}
</style>
